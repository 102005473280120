import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/reception",
    name: "reception",
    component: () =>
      import(/* webpackChunkName: "reception" */ "../views/ReceptionView.vue"),
  },
  {
    path: "/cashier",
    name: "cashier",
    component: () =>
      import(/* webpackChunkName: "cashier" */ "../views/CashierView.vue"),
  },
  {
    path: "/tickets",
    name: "tickets",
    component: () =>
      import(/* webpackChunkName: "tickets" */ "../views/TicketsView.vue"),
  },
  {
    path: "/tickets/:id",
    name: "ticket",
    component: () =>
      import(/* webpackCnunkName: "ticket" */ "../views/TicketView.vue"),
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
